import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { IWardDTO, defaultWard } from '../../../interface/VaccinationInterface';
import { Modal } from 'react-bootstrap';
import { addToast } from '../../../utils/toastNotifications';
import { isFormValidated } from '../../../utils/formUtils';

const AddWardModal = ({
  show,
  close,
  addWardData,
}: {
  show: boolean;
  close: Function;
  addWardData?: Function;
}) => {
  const [wardData, setWardData] = useState<IWardDTO>(defaultWard);

  const handleFormChange = (input: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setWardData({
      ...wardData,
      [input]: value,
    });
  };

  const addWard = (event: FormEvent) => {
    if (!isFormValidated('ward-modal')) {
      return;
    }
    event.preventDefault();

    if (addWardData) {
      addWardData(wardData);
      addToast('Bio Data added', 'success');
      setWardData(defaultWard);
    }
  };

  useEffect(() => {
    if (show) {
      setWardData(defaultWard);
    }
  }, [show]);

  return (
    <Modal className='font-utile' centered show={show} onHide={() => close()} backdrop={'static'}>
      <form id='ward-modal'>
        <Modal.Header closeButton>
          <Modal.Title>Add Female Child Bio Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='form-group mt-4'>
            <label className='form-label'>Name of child</label>
            <input
              className='form-control'
              type='text'
              required
              value={wardData.fullName}
              onChange={handleFormChange('fullName')}
            />
          </div>
          <div className='form-group mt-4'>
            <label className='form-label'>Age of child (9 - 14 years)</label>
            <input
              className='form-control'
              type='number'
              min={6}
              max={17}
              required
              value={wardData.age}
              onChange={handleFormChange('age')}
            />
          </div>
          <label htmlFor='ward-2' className='label-section rounded-1 p-3 mt-4'>
            <div className='form-check'>
              <input
                type='checkbox'
                className='form-check-input'
                checked={wardData.isAttendingSchool}
                onChange={handleFormChange('isAttendingSchool')}
                id='ward-2'
              />
              <label className='form-check-label' htmlFor='ward-2'>
                Does this child attend school ?
              </label>
            </div>
          </label>
        </Modal.Body>
        <Modal.Footer>
          <button
            className='btn btn-secondary text-white vitals-bg-primary'
            type='submit'
            onClick={addWard}
          >
            Add Bio Data
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddWardModal;
