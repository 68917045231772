import { Link } from 'react-router-dom';
import guide from '../../../assets/images/vitals/vaccine-flowchart.png';

const VaccinationGuide = () => {
  return (
    <section className='pt-5 content-section pb-5 mb-5 rounded-0'>
      <Link to='/vaccinations/home' className='text-decoration-none text-center d-block'>
        <img
          src={require('../../../assets/images/vitals/logo.svg').default}
          className='img-fluid'
          alt='logo'
        />
      </Link>

      <div className='mt-5 text-center'>
        <img src={guide} className='img-fluid' alt='wellahealth' />
      </div>

      <div className='row justify-content-center mt-4'>
        <div className='col-sm-6 col-lg-4 col-xl-3 d-grid mt-3'>
          <Link
            to={guide}
            className='btn btn-outline-secondary vitals-bg-primary fs-18 fw-500 text-white vitals-border-primary px-5'
          >
            Download
          </Link>
        </div>
      </div>
    </section>
  );
};

export default VaccinationGuide;
