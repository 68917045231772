import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { endpoints } from '../../utils/URL';
import Select from 'react-select';
import { IPharmacyListDTO } from '../../interface/PharmacyInterface';
import { ISelectOptions } from '../../interface/CommonInterface';
import { Roles } from '../../utils/mappings';
import { addToast } from '../../utils/toastNotifications';
import PageTour from '../common/PageTour';
import { adminTourSteps } from '../../utils/pageTourSteps';

const SideMenu = () => {
  const [pharmacyList, setPharmacyList] = useState<IPharmacyListDTO[]>([]);
  const [selectOptions, setSelectOptions] = useState<ISelectOptions[]>([]);
  const { dispatch, appState } = useContext(AppContext);

  const history = useHistory();

  const setActiveNav = (event: any) => {
    //remove all active navs
    let navLinks = document.getElementsByClassName('nav-link');
    for (let i = 0; i < navLinks.length; i++) {
      navLinks[i].classList.remove('active');
    }
    //set active for the current one
    event.target.classList.add('active');

    //scroll to top.  <span id='scroll-here'></span> is found in index.tsx
    let scrollHere: any = document.getElementById('scroll-here');
    scrollHere.scrollIntoView();

    //close side menu if open

    // let sideBar: any = document.getElementById('sidebarMenu');
    // sideBar.classList['remove']('show');
  };

  const logOut = (event: any) => {
    event.preventDefault();
    dispatch({
      type: 'LOG_OUT',
    });
  };

  const isSysAdmin = useCallback(() => {
    const userRole: string = appState.entitlements.role;
    return userRole === Roles.SysAdmin;
  }, [appState]);

  const loadPharamcyList = useCallback(async () => {
    let url = `${endpoints.pharmacies.mainUrl}/client/${1}/list`;

    if (isSysAdmin()) {
      url = endpoints.pharmacies.pharmacyList;
    }

    try {
      const response = await axios.get(url);
      let selectData = response.data.map((val: IPharmacyListDTO) => {
        return {
          label: val.pharmacyName,
          value: val.pharmacyCode,
        };
      });
      setSelectOptions(selectData);
      setPharmacyList(response.data);
    } catch (error) {
      addToast('Error occured while fetching pharmacy list', 'error');
    }
  }, [isSysAdmin]);

  const changePharmacy = (selectedValue: any) => {
    //get the full onject
    const pharmacy = pharmacyList.filter((x) => x.pharmacyCode === selectedValue.value)[0];
    //set in state
    dispatch({
      type: 'SELECT_PHARMACY',
      payload: pharmacy,
    });
    //go to pharmacy details
    history.push('/admin/pharmacy/details');
  };

  const goToPharmacy = () => {
    //prompt user
    //set the pharmacy in context to selected pharmacy
    dispatch({
      type: 'GOTO_PHARMACY',
      payload: appState.selectedPharmacy,
    });
    //navigate to pharmacy
    history.push('/entry/');
  };

  useEffect(() => {
    loadPharamcyList();
  }, [loadPharamcyList]);

  return (
    // col-md-3 col-lg-2 d-md-block sidebar show collapse
    <nav id='sidebarMenu' className='col-md-3 col-lg-2 sidebar show collapse'>
      <PageTour
        steps={adminTourSteps}
        page='admin'
        run={localStorage.getItem('adminTour') === 'completed' ? false : true}
      />
      <div className='sidebar-sticky pt-3'>
        <div style={{ color: '#fff' }}>Select a pharmacy</div>
        <Select onChange={changePharmacy} options={selectOptions} className='pharmacy-select' />
        {/* <select className='custom-select text-center'></select> */}
        <br />
        <hr />
        <ul className='nav flex-column'>
          <li className='nav-item nav-item-custom pharmacy-details'>
            <Link
              to='/admin/pharmacy/details'
              className='nav-link nav-link-custom active'
              onClick={setActiveNav}
            >
              Pharmacy Details
            </Link>
          </li>
          {isSysAdmin() && (
            <li className='nav-item nav-item-custom pharmacies'>
              <Link
                to='/admin/pharmacy/list'
                className='nav-link nav-link-custom'
                onClick={setActiveNav}
              >
                Pharmacies <span className='ms-2 bi bi-hospital'></span>
              </Link>
            </li>
          )}
          <li className='nav-item nav-item-custom messages'>
            <Link to='/admin/messages' className='nav-link nav-link-custom' onClick={setActiveNav}>
              Messages
              <span className='ms-2 bi bi-chat-text'></span>
            </Link>
          </li>
          <li className='nav-item nav-item-custom templates'>
            <Link to='/admin/templates' className='nav-link nav-link-custom' onClick={setActiveNav}>
              Templates
              <span className='ms-2 bi bi-file-earmark'></span>
            </Link>
          </li>
          <li className='nav-item nav-item-custom users'>
            <Link to='/admin/users' className='nav-link nav-link-custom' onClick={setActiveNav}>
              Users
              <span className='ms-2 bi bi-people'></span>
            </Link>
          </li>
          <li className='nav-item nav-item-custom wallet'>
            <Link to='/admin/wallets' className='nav-link nav-link-custom' onClick={setActiveNav}>
              Wallets <span className='ms-2 bi bi-wallet'></span>
            </Link>
          </li>
          <li className='nav-item nav-item-custom subscription'>
            <Link
              to={`/admin/${appState.selectedPharmacy.pharmacyId}/subscriptions`}
              className='nav-link nav-link-custom'
              onClick={setActiveNav}
            >
              Subscriptions
              <span className='ms-2 bi bi-receipt'></span>
            </Link>
          </li>
          {isSysAdmin() && (
            <li className='nav-item nav-item-custom subscription'>
              <Link
                to={`/admin/vitals`}
                className='nav-link nav-link-custom'
                onClick={setActiveNav}
              >
                Vitals Project
                <span className='ms-2 bi bi-receipt'></span>
              </Link>
            </li>
          )}
          <hr />
          <li className='nav-item nav-item-custom go-to-pharmacy'>
            <button className='btn' style={{ color: '#fff' }} onClick={goToPharmacy}>
              Go to {appState.selectedPharmacy.pharmacyName.split('|')[0]}
              <span className=' ms-2 bi bi-box-arrow-in-right'></span>
            </button>
          </li>
          <hr />
          <li className='nav-item-custom logout'>
            <div
              style={{ cursor: 'pointer' }}
              onClick={logOut}
              className='nav-link nav-link-custom'
            >
              Logout
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default SideMenu;
