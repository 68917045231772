import axios from 'axios';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IAppState, IMenuItem, initialMenu } from '../../interface/AppInterface';
import { endpoints } from '../../utils/URL';
import { addToast } from '../../utils/toastNotifications';
import { AppContext } from '../../context/AppContext';
import { formatDate } from '../../utils/dateTimeUtils';

const Menu = () => {
  const [menuItem, setMenuItem] = useState<IMenuItem>(initialMenu);
  const { appState, dispatch }: { appState: IAppState; dispatch: any } = useContext(AppContext);
  const pharmacyId: number = appState.pharmacyId;

  //get menu items
  const getMenu = useCallback(async () => {
    if (pharmacyId === 0) {
      dispatch({
        type: 'LOG_OUT',
      });
      return;
    }

    try {
      const url = `${endpoints.auth.mainUrl}/${pharmacyId}/menu`;
      const response = await axios.get(url);

      setMenuItem(response.data);
      dispatch({
        type: 'SET_MENU_ITEMS',
        payload: response.data,
      });
    } catch (error) {
      addToast('An error occured', 'error');
    }
  }, [pharmacyId, dispatch]);

  useEffect(() => {
    getMenu();
  }, [getMenu]);

  return (
    <div className='container'>
      {/* <PageTour
        steps={menuTourSteps}
        page='menu'
        run={localStorage.getItem('menuTour') === 'completed' ? false : true}
      /> */}
      <main style={{ marginTop: '15px' }}>
        <section className='container expire text-end'>
          <div className='row justify-content-center'>
            <div className='col-sm-10'>
              <div className='row justify-space-between'>
                <div className='col-sm-6 text-start'>
                  <Link to={'/vaccinations/home'} className='btn'>
                    Vital Project
                  </Link>
                </div>
                <div className='col-sm-6'>
                  <span className='expiry'>
                    <Link to='/entry'>
                      <i className='bi bi-clock'></i> Expiry:
                      <span
                        className={
                          new Date(menuItem.expiryDate).getTime() > new Date().getTime()
                            ? 'ms-1 text-success'
                            : 'ms-1 text-danger'
                        }
                      >
                        {formatDate(menuItem.expiryDate)}
                      </span>
                    </Link>
                  </span>
                  <span className='ms-4 smsCredit'>
                    <Link to='/entry'>
                      <i className='bi bi-cash'></i> Credit:
                      <span
                        className={
                          menuItem.smsUnits < 10 ? 'ms-1 text-danger' : 'ms-1 text-success'
                        }
                      >
                        {menuItem.smsUnits.toLocaleString()}
                      </span>
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* <span className='ms-4 topup'>
            <Link to='/entry'>
              <button className='btn btn-primary'>Top Up</button>
            </Link>
          </span> */}
        </section>

        <section className='container'>
          <div className='row justify-content-center'>
            <div className='col-sm-10'>
              <div className='row'>
                <div className='col-sm-6 col-md-3 col-6 viewPatients'>
                  <Link to='/patients' className='single-tab dashboard-tabs'>
                    <span className='ticker' id='ptCount'>
                      {menuItem.patientCount}
                    </span>
                    <div className='inner-tab'>
                      <img
                        src={require('../../assets/images/patients.svg').default}
                        alt='patient'
                        className='mobile hidden tablet hidden'
                      />
                      <p>View Patients</p>
                    </div>
                  </Link>
                </div>

                <div className='col-sm-6 col-md-3 col-6 sendMessages'>
                  <Link to='/messaging' className='single-tab dashboard-tabs'>
                    <span className='ticker' id='msgCount'>
                      {menuItem.messageCount}
                    </span>
                    <div className='inner-tab'>
                      <img
                        src={require('../../assets/images/chat.svg').default}
                        alt='chat'
                        className='mobile hidden tablet hidden'
                      />
                      <p>Send Messages</p>
                    </div>
                  </Link>
                </div>

                <div className='col-sm-6 col-md-3 col-6 viewRefills'>
                  <Link to='/refills' className='single-tab dashboard-tabs'>
                    <span className='ticker' id='refCount'>
                      {menuItem.refillCount}
                    </span>
                    <div className='inner-tab'>
                      <img
                        src={require('../../assets/images/vitamins.svg').default}
                        alt='refills'
                        className='mobile hidden tablet hidden'
                      />
                      <p>View Refills</p>
                    </div>
                  </Link>
                </div>

                <div className='col-sm-6 col-md-3 col-6 tasks'>
                  <Link to='/notifications' className='single-tab dashboard-tabs'>
                    <span className='ticker' id='notifCount'>
                      {menuItem.notificationCount}
                    </span>
                    <div className='inner-tab'>
                      <img
                        src={require('../../assets/images/tasks.svg').default}
                        alt='notifications'
                        className='mobile hidden tablet hidden'
                      />
                      <p>Do Tasks</p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Menu;
